import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import clsx from "clsx";
import PageSection from "components/PageSection";
import ContactForm from "components/ContactForm";

import "./Contact.scss";
import Footer from "views/Footer";

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader } = frontmatter;

  return (
    <PageSection
      className={clsx("image-card bg-gradient text-white text-center ", className)}
      id={anchor}
    >
      <Row className="justify-content-center font-weight-bold">
        <Col lg={8} className="text-center">
          <h1>{header}</h1>
          <p className="text-muted contact-subheader">{subheader}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={7} className="text-center mr-auto ml-auto">
          <ContactForm />
        </Col>
      </Row>
      <Row>
        <Footer/>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
