import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Container, Card } from "react-bootstrap";
import "./ImageCard.scss";

const ImageCard = ({ className, descriptionText, header, extraInfo }) => {
  return (
    <Card className={clsx("image-card bg-gradient text-white text-center", className)}>
      <Container>
        <div className="intro-text">
          <div className="intro-lead-in">{header}</div>
          <p className="intro-heading">
            We <span className="design">design</span>, <span className="develop">develop</span> & {" "}
            <span className="deliver">deliver</span>
          </p>
          <div className="intro-description">{descriptionText}</div>
          {extraInfo}
        </div>
      </Container>
    </Card>
  );
};

ImageCard.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  header: PropTypes.string,
  extraInfo: PropTypes.any,
};

ImageCard.defaultProps = {
  className: null,
  descriptionText: "",
  header: "",
  extraInfo: null,
};

export default ImageCard;
