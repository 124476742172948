import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Dribbble = ({ userName }) => (
  <CircleIcon href={`https://dribbble.com/${userName}`} iconName="DribbbleIcon" />
);

Dribbble.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default Dribbble;
