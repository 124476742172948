import React from "react";
import PropTypes from "prop-types";


import FooterItem from "components/FooterItem";


import "./Footer.scss"

const Footer = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    copyright: copy,
    privacyHref: privacyLink,
    privacyText: privacyString,
    termsHref: termsLink,
    termsText: termsString,
  } = frontmatter;

  return (
    <FooterItem
      copyright={copy}
      privacyHref={privacyLink}
      privacyText={privacyString}
      termsHref={termsLink}
      termsText={termsString}
    />
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
