import makeFAIcon from "utils/makeFAIcon";

import {
  faDraftingCompass,
  faCode,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";

export const ShoppingCartIcon = makeFAIcon(faDraftingCompass);
export const LaptopIcon = makeFAIcon(faCode);
export const LockIcon = makeFAIcon(faDesktop);
