import React from "react";
import PropTypes from "prop-types";

import CircleFAButton from "components/CircleFAButton";
import "./ServiceItem.scss";

const ServiceItem = ({ iconName, header, content, textColor }) => {
  let iconPart;
  if (iconName) {
    iconPart = <CircleFAButton iconName={iconName} />;
  }

  return (
    <>
      <div className={`service-item-top text-color-${textColor}`}>
        {iconPart}
        <h4 className="service-item-heading">{header}</h4>
      </div>
      <p className="service-item-bottom border">{content}</p>
    </>
  );
};

ServiceItem.propTypes = {
  iconName: PropTypes.string,
  textColor: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
};

ServiceItem.defaultProps = {
  iconName: null,
  textColor: "",
  header: "",
  content: "",
};

export default ServiceItem;
