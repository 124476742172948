import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Globe = ({ userName }) => (
  <CircleIcon href={`${userName}`} iconName="GlobeIcon" />
);

Globe.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default Globe;
