import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import "./FooterItem.scss";

const FooterItem = ({ copyright, privacyHref, privacyText, termsHref, termsText }) => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center text-center">
          <Col lg={5} className="text-lg-left">
            {copyright}
          </Col>
          <Col lg={7} className="text-lg-right">
            <a href={privacyHref} className="footer-text">
              {privacyText}
              <span className="dot" />
            </a>
            <a href={termsHref} className="footer-text"> {termsText} </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

FooterItem.propTypes = {
  copyright: PropTypes.string.isRequired,
  privacyHref: PropTypes.string.isRequired,
  privacyText: PropTypes.string.isRequired,
  termsHref: PropTypes.string.isRequired,
  termsText: PropTypes.string.isRequired,
};

export default FooterItem;
