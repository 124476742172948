import React from "react";
import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import Image from "components/Image";

import "./PortfolioItem.scss";

const PortfolioDetailDialog = ({
  onHide,
  imageFileName,
  imageAlt,
  header,
  subheader,
  content,
  extraInfo,
  ...restProps
}) => {
  return (
    <Modal
      {...restProps}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="modal-top border-bottom-0">
        <Modal.Title id="contained-modal-title-vcenter ">
          <div className="title">{header}</div>
          <p className="text-muted modal-top-header">{subheader}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-auto">
        <div className="modal-description border border-bottom-0">{content}</div>
        <div className="extra-info border border-top-0">{extraInfo}</div>
        <Image
          className="img-fluid d-block image border"
          fileName={imageFileName}
          alt={imageAlt || header || subheader}
        />
      </Modal.Body>
    </Modal>
  );
};

PortfolioDetailDialog.propTypes = {
  onHide: PropTypes.func,
  imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  content: PropTypes.string,
  extraInfo: PropTypes.any,
};

PortfolioDetailDialog.defaultProps = {
  onHide: null,
  imageFileName: "",
  imageAlt: null,
  header: "",
  subheader: "",
  content: "",
  extraInfo: null,
};

export default PortfolioDetailDialog;
