import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";

import "./CircleFAButton.scss";
import { Form } from "react-bootstrap";

import "./ContactForm.scss";

const ContactForm = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    emailjs.init(process.env.GATSBY_EMAILJS_USER_ID);
  }, []);

  const handleChange = (name) => (e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    setSending(true);
    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        e.target,
      )
      .then(
        () => {
          e.target.reset();
          setSending(false);
          setSuccess(true);
        },
        (err) => {
          setSending(false);
          setError(err.message);
        },
      );
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="contact-form">
        <Form.Group>
          <Form.Control
            className="contact-form-input"
            type="text"
            required
            placeholder="Name"
            onChange={handleChange("name")}
            name="name"
          />
          <Form.Control
            className="contact-form-input"
            type="email"
            required
            placeholder="Email"
            onChange={handleChange("email")}
            name="email"
          />
          <Form.Control
            className="contact-form-input"
            name="message"
            required
            as="textarea"
            placeholder="Type your message"
            style={{ height: "120px" }}
            onChange={handleChange("message")}
          />
        </Form.Group>
        <button className="submit" type="submit" disabled={sending}>
          {sending ? "Sending..." : "SEND A MESSAGE"}
        </button>
        {success && (
          <div className="alert alert-success mt-2" role="alert">
            Your message has been sent, Thank you!
            <a
              href="#"
              className="float-right text-dark"
              onClick={(e) => {
                e.preventDefault();
                setSuccess(false);
              }}
            >
              X
            </a>
          </div>
        )}

        {error && (
          <div className="alert alert-danger mt-2" role="alert">
            Failed to send message: {error}
            <a
              href="#"
              className="float-right text-dark"
              onClick={(e) => {
                e.preventDefault();
                setError(false);
              }}
            >
              X
            </a>
          </div>
        )}
      </Form>
    </>
  );
};

export default ContactForm;
